import React from 'react';
import { CurrencyText } from './CurrencyText';
import { DateText } from './DateText';

export const PaymentInstructions = ({ dataBilling }) =>
  dataBilling.discountValue ? (
    <>
      Para pagamento até dia <DateText date={dataBilling.discountLimitDate} />{' '}
      receber valor de{' '}
      <CurrencyText
        value={dataBilling.invoiceAmount - (dataBilling?.discountValue || 0)}
      />
    </>
  ) : (
    <></>
  );
