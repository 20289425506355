import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { styles } from './pdfStyles';
import { utcToGmt } from '../../components/utils';
import { DateText, DocumentLabel, DocumentNumber } from '../../components';
import { InvoiceItems } from './InvoiceItems';
import { Receipt } from './Receipt';

export const PdfInvoice = ({ dataBilling = {} }) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Text style={styles.h1}>Fatura</Text>
        <View style={styles.meta}>
          <View style={styles.column}>
            <Text style={styles.label}>Cliente</Text>
            <Text style={styles.text}>{dataBilling?.payerName}</Text>
            <Text style={styles.label}>Data de Vencimento</Text>
            <Text style={styles.text}>
              <DateText date={dataBilling.invoiceDueDate} />
            </Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.label}>
              <DocumentLabel number={dataBilling.payerDocumentNumber} />
            </Text>
            <Text style={styles.text}>
              <DocumentNumber number={dataBilling.payerDocumentNumber} />
            </Text>
            <Text style={styles.label}>Endereço</Text>
            <Text style={styles.text}>{dataBilling?.payerAddress}</Text>
          </View>
        </View>
        <Text style={styles.h2}>Detalhes da Fatura</Text>
        <View style={styles.meta}>
          <View style={styles.column}>
            <Text style={styles.label}>Data da Emissão</Text>
            <Text style={styles.text}>
              <DateText date={utcToGmt(dataBilling.invoiceCreatedDate)} />
            </Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.label}>Identificador da Fatura</Text>
            <Text style={styles.text}>{dataBilling.invoiceIdentify}</Text>
          </View>
        </View>
        <InvoiceItems items={dataBilling.invoiceItems} />
        <Receipt dataBilling={dataBilling} />
      </Page>
    </Document>
  );
};
