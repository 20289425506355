import styled from 'styled-components/macro';

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 268px;

  .MuiAlert-message {
    font-size: 16px;
  }

  @media (max-width: 600px) {
    height: 40px;
  }
`;

export { Footer };
