import React from 'react';
import moment from 'moment';
import { CurrencyText } from './CurrencyText';
import { DateText } from './DateText';

export const InvoiceFines = ({ dataBilling }) => {
  const dontPayDate = moment(dataBilling.invoiceDueDate).add(1, 'day');

  return !dataBilling.isThereFine ? (
    <>
      Boleto pagável até o dia <DateText date={dataBilling.invoiceDueDate} />
    </>
  ) : (
    <>
      <div>
        Multa de <CurrencyText value={dataBilling.invoiceFinesAmount} /> a{' '}
        partir da data <DateText date={dontPayDate} />
      </div>
    </>
  );
};
