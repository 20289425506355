import React from 'react';
import { CopyButton } from './CopyButton';
import { PrintButton } from './PrintButton';

import * as S from './styles';

export const InvoiceActions = ({ dataBilling, ...props }) => (
  <>
    <S.ButtonContainer {...props}>
      {dataBilling.invoiceStatus === 'Pending' && (
        <CopyButton dataBilling={dataBilling} />
      )}
      <PrintButton className='print-button' dataBilling={dataBilling} />
    </S.ButtonContainer>
  </>
);
