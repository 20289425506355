const statusTranslations = {
  draft: 'Rascunho',
  pending: 'Pendente',
  expired: 'Expirado',
  paid: 'Pago',
  canceled: 'Cancelado'
};

export const InvoiceStatus = ({ status }) =>
  (status && statusTranslations[status.toLowerCase()]) || '';
