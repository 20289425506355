import moment from 'moment';

export const isCPF = (value = '') => {
  return (
    (value.includes('.') && value.length <= 14) ||
    (!value.includes('.') && value.length <= 11)
  );
};

export const cpfMask = (value = '') => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const cnpjMask = (value = '') => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{3})/, '$1.$2')
    .replace(/(\d{3})(\d{3})/, '$1.$2')
    .replace(/(\d{3})(\d{4})/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const phoneMask = (value = '') => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1');
};

export const barCodeMask = (value = '') => {
  return value
    .replaceAll(' ', '')
    .replaceAll('.', '')
    .replaceAll('/', '')
    .replaceAll('-', '');
};

export const toBarCodeNumber = (value = '') => {
  return (
    Number(
      value
        .replaceAll(' ', '')
        .replaceAll('.', '')
        .replaceAll('/', '')
        .replaceAll('-', '')
    ) || 0
  );
};

export const utcToGmt = (newDate) => {
  return moment(newDate).add(-3, 'hour').toDate();
};

export function encodeSvgString(svg) {
  const decoded = unescape(encodeURIComponent(svg));
  const b64String = btoa(decoded);
  const imgSource = `data:image/svg+xml;base64,${b64String}`;
  return imgSource;
}
