import React from 'react';
import { utcToGmt } from '../../components/utils';

import * as S from './styles';
import { CurrencyText } from '../../components/CurrencyText';
import { DateText } from '../../components';

export const Bill = ({ dataBilling }) => {
  return (
    <S.ContainerTableBilling className='table-billing'>
      <S.SubTitle>Detalhes da Fatura</S.SubTitle>

      <S.Section>
        <div style={{ display: 'flex' }}>
          <S.Text>Data da Emissão: </S.Text>
          <span style={{ width: '4px' }} />
          <S.SimpleText>
            <DateText date={utcToGmt(dataBilling.invoiceCreatedDate)} />
          </S.SimpleText>
        </div>

        <div className='invoice-identify' style={{ display: 'flex' }}>
          <S.Text>Identificador da Fatura:</S.Text>
          <span className='separator' style={{ width: '4px' }} />
          <S.SimpleText>{dataBilling.invoiceIdentify}</S.SimpleText>
        </div>
      </S.Section>

      <S.Table>
        <S.TableHeader>
          <S.TableRow>
            <S.TableHead>Item</S.TableHead>
            <S.TableHead>Valor</S.TableHead>
          </S.TableRow>
        </S.TableHeader>
        <S.TableBody>
          {dataBilling.invoiceItems.map((item, index) => (
            <S.TableRowBody key={index}>
              <S.TableCell>{item.description}</S.TableCell>
              <S.TableCell>
                <CurrencyText value={item.amount} />
              </S.TableCell>
            </S.TableRowBody>
          ))}
        </S.TableBody>
      </S.Table>
    </S.ContainerTableBilling>
  );
};
