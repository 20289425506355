import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import Boleto from 'boleto.js';
import { barCodeMask } from '../../components/utils';
import { Svg } from './Svg';

const styles = {
  wrapper: {
    alignItems: 'center'
  },
  image: {
    marginTop: 20,
    height: 30,
    width: '70%'
  },
  text: {
    fontSize: 12,
    textTransform: 'uppercase',
    marginBottom: 5
  }
};

export const BarCode = ({ code }) => {
  const barCode = barCodeMask(code);
  const boleto = Number(barCode) !== 0 ? new Boleto(barCode).toSVG() : '';

  return (
    <View style={styles.wrapper}>
      <Text style={styles.text}>linha digitável</Text>
      <Text style={styles.text}>{barCode}</Text>
      {Number(barCode) !== 0 && (
        <View style={styles.image}>
          <Svg svgXml={boleto} />
        </View>
      )}
    </View>
  );
};
