import React from 'react';
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import { BaseButton } from '../../components/Buttons';
import { ReactComponent as PrintOut } from '../../assets/icons/print-out.svg';
import { PdfInvoice } from '../Pdf/PdfInvoice';
import { isFirefox } from 'react-device-detect';

export const PrintButton = ({ dataBilling, ...props }) => {
  const openPDF = (url) => () => {
    window.open(url, '_blank');
  };
  const invoice = <PdfInvoice dataBilling={dataBilling} />;

  return isFirefox ? (
    <PDFDownloadLink
      document={invoice}
      fileName={`${dataBilling.invoiceIdentify}.pdf`}
    >
      {({ loading, error }) =>
        !error && (
          <BaseButton disabled={loading} startIcon={<PrintOut />} {...props}>
            {loading ? `Carregando...` : `Baixar`}
          </BaseButton>
        )
      }
    </PDFDownloadLink>
  ) : (
    <BlobProvider document={invoice}>
      {({ url, loading, error }) =>
        !error && (
          <BaseButton
            disabled={loading}
            startIcon={<PrintOut />}
            onClick={openPDF(url)}
            {...props}
          >
            {loading ? `Carregando...` : `Imprimir`}
          </BaseButton>
        )
      }
    </BlobProvider>
  );
};
