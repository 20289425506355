import { StyleSheet, Font } from '@react-pdf/renderer';

const interUrl = `https://cdnjs.cloudflare.com/ajax/libs/inter-ui/3.19.3/Inter%20(web)`;

Font.register({
  family: 'Inter',
  fonts: [
    {
      src: `${interUrl}/Inter-Regular.woff?v=3.19`,
      fontWeight: 400
    },
    {
      src: `${interUrl}/Inter-SemiBold.woff?v=3.19`,
      fontWeight: 600
    }
  ]
});

export const lightGray = '#f3f5f8';
export const separatorColor = '#CCC';

export const styles = StyleSheet.create({
  page: {
    fontSize: 9,
    fontFamily: 'Inter',
    flexDirection: 'column',
    padding: '1.7cm 1.3cm'
  },
  h1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: '4mm',
    display: 'block'
  },
  h2: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: '2mm',
    display: 'block'
  },
  label: {
    display: 'block',
    fontWeight: 600
  },
  text: {
    display: 'block'
  },
  meta: {
    flexDirection: 'row',
    marginBottom: '4mm',
    width: '100%'
  },
  column: {
    width: '50%'
  },
  recipient: {
    flexDirection: 'row'
  },
  recipientColumn: {
    flexDirection: 'column',
    width: '50%',
    overflow: 'hidden'
  },
  recipientSeparator: {
    borderRightColor: separatorColor,
    borderRightWidth: 1,
    paddingRight: '4mm',
    marginRight: '4mm'
  },
  status: {
    alignSelf: 'flexEnd',
    backgroundColor: lightGray,
    padding: '2mm'
  },
  recipientRightCol: {
    marginBottom: 10,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between'
  }
});
