import React from 'react';
import { CircularProgress } from '@material-ui/core';

import * as S from './styles';

export const Loading = () => {
  return (
    <S.Wrapper>
      <CircularProgress size='300' />
    </S.Wrapper>
  );
};
