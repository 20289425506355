import React from 'react';
import Boleto from 'boleto.js';
import { barCodeMask } from '../../components/utils';

import * as S from './styles';

export const BarCode = ({ billingNumber }) => {
  const barCode = barCodeMask(billingNumber);
  const boleto = Number(barCode) !== 0 ? new Boleto(barCode).toSVG() : '';

  return (
    <S.WrapperBarCode>
      <S.ContainerBarCode>
        <S.BarCodeTitle>linha digitavel</S.BarCodeTitle>
        <S.BarCodeTitle>{barCode}</S.BarCodeTitle>
        {Number(barCode) !== 0 && (
          <S.BarCode
            dangerouslySetInnerHTML={{
              __html: boleto
            }}
          ></S.BarCode>
        )}
      </S.ContainerBarCode>
    </S.WrapperBarCode>
  );
};
