import React from 'react';
import Alert from '@material-ui/lab/Alert';

import * as S from './styles';

export const Footer = () => {
  return (
    <S.Footer>
      {process.env.REACT_APP_VERCEL_GIT_COMMIT_REF !== 'main' && (
        <Alert>
          <b>{process.env.REACT_APP_VERCEL_GIT_COMMIT_REF}</b>
        </Alert>
      )}
    </S.Footer>
  );
};
