import React from 'react';

import * as S from './styles';
import { DateText, DocumentLabel, DocumentNumber } from '../../components';

export const Payer = ({ dataBilling }) => {
  return (
    <S.Section>
      <S.PayerContainer>
        <S.Text>Cliente</S.Text>
        <S.SimpleText>{dataBilling?.payerName}</S.SimpleText>
        <S.Text>Data de Vencimento</S.Text>
        <S.SimpleText>
          <DateText date={dataBilling.invoiceDueDate} />
        </S.SimpleText>
      </S.PayerContainer>
      <S.PayerContainer>
        <S.Text>
          <DocumentLabel number={dataBilling.payerDocumentNumber} />
        </S.Text>
        <S.SimpleText>
          <DocumentNumber number={dataBilling.payerDocumentNumber} />
        </S.SimpleText>
        <S.Text>Endereço</S.Text>
        <S.SimpleText>{dataBilling?.payerAddress}</S.SimpleText>
      </S.PayerContainer>
    </S.Section>
  );
};
