import styled from 'styled-components/macro';
import { ReactComponent as BigRectangle } from '../../assets/bigRectangle.svg';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  position: relative;
  width: 100%;
  z-index: 2;

  .mobile-spacer {
    overflow: hidden;
    height: 0;
    width: 0;
  }

  @media (max-width: 600px) {
    padding: 0 8px;

    .disable-mobile {
      overflow: hidden;
      height: 0;
      width: 0;
    }

    .mobile-spacer {
      overflow: visible;
      height: 1px;
      width: 100%;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1143px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 38px;

  background: #fff;
  box-shadow: 0px 8px 16px rgba(23, 43, 77, 0.08);
  border-radius: 8px;

  .page-break {
    display: none;
  }

  @media (max-width: 600px) {
    padding: 8px;

    .billing-title {
      display: none;
    }

    .spacer-mobile {
      max-width: 91%;
      margin: 22px auto;
    }
  }
`;

const RecipientWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 24px 24px;
  width: 100%;
  box-sizing: border-box;

  background: #fafafa;
  border-radius: 8px;
`;

const RecipientDataWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  background: #fafafa;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  width: 100%;

  .spacer-recipient {
    margin: 2px 0 22px;
  }
`;

const ContainerHeader = styled.div`
  max-width: 1143px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  @media (max-width: 600px) {
    justify-content: center;
    flex-direction: column;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 24px 0;

  .MuiButtonBase-root {
    margin-left: 24px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    margin: 32px auto 8px;

    .MuiButtonBase-root {
      margin-left: 0;
    }

    .print-button {
      display: none;
    }
  }
`;

const Title = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #1a305a;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
`;

const RecipientTitle = styled(Title)`
  font-weight: normal;
`;

const SubTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #1a305a;

  @media (max-width: 600px) {
    padding: 0 16px;
    margin-bottom: 4px;
  }
`;

const Spacer = styled.div`
  width: 100%;
  height: 1px;
  background: #d7dfe9;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 22px 0px;
`;

const Text = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1a305a;
`;

const TextWithSpace = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #001947;
`;

const SimpleText = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #4d5e7e;
`;

const SimpleTextWithoutSpace = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #001947;
`;
const SimpleTextWithtSpace = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #001947;
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 16px;

    p,
    span {
      margin-bottom: 8px;
    }

    .invoice-identify {
      flex-direction: column;

      .separator {
        display: none;
      }
    }
  }
`;

const SectionRecipient = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title-recipient {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
  }

  .logo {
    max-width: 80px;
    max-height: 75px;
    margin: 0 12px 0 0;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;

    .title-recipient {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .logo {
      max-width: 60px !important;
      margin: 0 12px 0 0;
    }

    .separator-title {
      height: 21px;
    }

    .title {
      margin: 0 0 0 12px;
      font-size: 18px;
    }

    .separator-recipient {
      display: none;
    }
  }
`;

const ContainerDate = styled.div`
  max-height: 41px;

  p {
    margin: 1px 0;
  }

  @media (max-width: 600px) {
    margin: 12px 0;
  }
`;

const PayerContainer = styled.div`
  flex: 1 1;
  max-width: 325px;
`;

const RecipientDataContainer = styled.div`
  flex: 1 1;
  width: 100%;
  position: relative;
`;

const Table = styled.table`
  width: 100%;
  text-align: left;
  border: none;
  margin: 18px 0;

  @media (max-width: 600px) {
    padding: 0 16px;
  }
`;

const TableRow = styled.tr`
  height: 46px;
  background: #f3f5f8;
  border-radius: 4px 4px 0px 0px;

  @media (max-width: 600px) {
    height: 36px;
  }
`;

const TableHead = styled.th`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1a305a;
  padding-left: 16px;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const TableHeader = styled.thead``;

const TableBody = styled.tbody``;

const TableRowBody = styled.tr`
  height: 46px;
  background: #fff;

  @media (max-width: 600px) {
    height: 36px;
  }
`;

const TableCell = styled.td`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #1a305a;
  padding-left: 16px;
  border-bottom: 1px solid #d7dfe9;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const DueDate = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ff3b30;
`;

const Amount = styled(DueDate)`
  color: #34c759;
`;

const Divider = styled(BigRectangle)`
  margin: 0 24px;
`;

const TitleWithMarginBotton = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: #1a305a;
  margin-bottom: 16px;
`;

const TitleWithMargin = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: #1a305a;
  margin-top: 16px;
  margin-bottom: 2px;
`;

const TextWithMarginBotton = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;

  color: #001947;
  margin-bottom: 16px;
`;

const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  position: absolute;
  top: 0;
  right: 0;
  min-width: 100px;
  max-width: 112px;
  max-height: 38px;

  background: #fbeabb;
  border-radius: 4px;

  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1a305a;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

const MobileStatus = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;

    width: 100%;
    max-height: 38px;
    margin-bottom: 24px;

    background: #fbeabb;
    border-radius: 4px;

    span {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #1a305a;
    }
  }
`;

const BarCodeTitle = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  word-break: break-word;
  margin-bottom: 8px;

  color: #1a305a;
`;

const ContainerBarCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const BarCode = styled.div`
  width: 598px;
  height: 74px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const RecipientBankDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 18px 0 0;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const WrapperBarCode = styled.div``;

const ContainerLogo = styled.div`
  margin: 24px 0;
`;

const ContainerTableBilling = styled.div`
  width: 100%;
`;

const Space = styled.div`
  width: 5px;
`;

export {
  Main,
  Wrapper,
  RecipientWrapper,
  RecipientDataWrapper,
  Container,
  ContainerHeader,
  ButtonContainer,
  Title,
  RecipientTitle,
  SubTitle,
  Spacer,
  Text,
  TextWithSpace,
  SimpleText,
  SimpleTextWithoutSpace,
  SimpleTextWithtSpace,
  Section,
  SectionRecipient,
  ContainerDate,
  PayerContainer,
  RecipientDataContainer,
  Table,
  TableRow,
  TableHead,
  TableHeader,
  TableBody,
  TableRowBody,
  TableCell,
  DueDate,
  Amount,
  Divider,
  TextWithMarginBotton,
  TitleWithMarginBotton,
  TitleWithMargin,
  Status,
  MobileStatus,
  BarCodeTitle,
  ContainerBarCode,
  BarCode,
  RecipientBankDetails,
  WrapperBarCode,
  ContainerLogo,
  ContainerTableBilling,
  Space
};
