import React from 'react';

import * as S from '../containers/Header/styles';

export const Page404 = () => {
  return (
    <S.Page404>
      <p>Desculpe, esta página não existe.</p>
      <img src='/404.png' />
    </S.Page404>
  );
};
