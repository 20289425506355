import React from 'react';
import * as S from './styles';
import { ReactComponent as Rectangle } from '../../assets/rectangle.svg';
import { ReactComponent as Bs2Logo } from '../../assets/bs2-logo.svg';
import { CurrencyText } from '../../components/CurrencyText';
import { DateText, InvoiceStatus } from '../../components';

export const Recipient = ({ dataBilling }) => {
  return (
    <S.Container>
      <S.SectionRecipient>
        <div className='title-recipient'>
          <Bs2Logo className='logo' />
          <Rectangle className='separator-title' />
          <S.RecipientTitle className='title'>Boleto Bancário</S.RecipientTitle>
        </div>
        <Rectangle className='separator-recipient' />
        <S.ContainerDate>
          <S.DueDate>Data de vencimento</S.DueDate>
          <S.SimpleText>
            <DateText date={dataBilling.invoiceDueDate} />
          </S.SimpleText>
        </S.ContainerDate>
        <Rectangle className='separator-recipient' />
        <S.ContainerDate>
          <S.Amount>Valor Total</S.Amount>
          <S.SimpleText>
            <CurrencyText value={dataBilling.invoiceAmount} />
          </S.SimpleText>
        </S.ContainerDate>
      </S.SectionRecipient>
      <S.Spacer className='spacer' />
      <S.MobileStatus>
        <span>
          <InvoiceStatus status={dataBilling.invoiceStatus} />
        </span>
      </S.MobileStatus>
    </S.Container>
  );
};
