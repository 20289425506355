import React from 'react';
import { utcToGmt } from '../../components/utils';

import * as S from './styles';
import {
  DocumentLabel,
  DocumentNumber,
  PaymentLocation,
  PaymentInstructions,
  DateText,
  InvoiceFines,
  InvoiceStatus,
  InvoiceInterest
} from '../../components';

const Guarantor = ({ dataBilling }) =>
  dataBilling.guarantorDocumentNumber ? (
    <>
      <S.TitleWithMarginBotton>Beneficiário:</S.TitleWithMarginBotton>
      <S.SimpleTextWithoutSpace>
        {dataBilling?.guarantorName}
      </S.SimpleTextWithoutSpace>
      <S.Text>
        <DocumentLabel number={dataBilling.guarantorDocumentNumber} />:
        <S.Space/>
        <S.SimpleTextWithoutSpace>
          <DocumentNumber number={dataBilling.guarantorDocumentNumber} />
        </S.SimpleTextWithoutSpace>
      </S.Text>
    </>
  ) : (
    <></>
  );

export const RecipientData = ({ dataBilling }) => {
  return (
    <>
      <S.RecipientDataWrapper>
        <S.RecipientDataContainer>
          <Guarantor dataBilling={dataBilling} />
          {dataBilling?.guarantorDocumentNumber ? (
            <S.TitleWithMargin>Intermediado por:</S.TitleWithMargin>
          ) : (
            <S.TitleWithMarginBotton>Intermediado por:</S.TitleWithMarginBotton>
          )}
          <S.SimpleTextWithoutSpace>
            {dataBilling.recipientName}
          </S.SimpleTextWithoutSpace>
          <S.Text>
            <DocumentLabel number={dataBilling.recipientDocumentNumber} />:
            <S.Space />
            <S.SimpleTextWithoutSpace>
              <DocumentNumber number={dataBilling.recipientDocumentNumber} />
            </S.SimpleTextWithoutSpace>
          </S.Text>
          <S.RecipientBankDetails>
            <div>
              <S.TitleWithMarginBotton>NOSSO NÚMERO</S.TitleWithMarginBotton>
              <S.SimpleTextWithtSpace>
                {dataBilling.ourNumber}
              </S.SimpleTextWithtSpace>
            </div>
            <div>
              <S.TitleWithMarginBotton>
                Número do Documento
              </S.TitleWithMarginBotton>
              <S.SimpleTextWithtSpace>
                {dataBilling?.document}
              </S.SimpleTextWithtSpace>
            </div>
          </S.RecipientBankDetails>
        </S.RecipientDataContainer>

        <S.Divider className='disable-mobile' />

        <S.RecipientDataContainer>
          <S.TitleWithMarginBotton>DATA DE EMISSÃO</S.TitleWithMarginBotton>
          <S.TextWithMarginBotton>
            <DateText date={utcToGmt(dataBilling.invoiceCreatedDate)} />
          </S.TextWithMarginBotton>
          <S.TitleWithMarginBotton>LOCAL DE PAGAMENTO</S.TitleWithMarginBotton>
          <S.TextWithMarginBotton>
            <PaymentLocation />
          </S.TextWithMarginBotton>
          <S.TitleWithMarginBotton>INSTRUÇÕES</S.TitleWithMarginBotton>
          <S.SimpleTextWithoutSpace>
            <PaymentInstructions dataBilling={dataBilling} />
          </S.SimpleTextWithoutSpace>
          <S.SimpleTextWithoutSpace>
            <InvoiceFines dataBilling={dataBilling} />
          </S.SimpleTextWithoutSpace>
          <S.SimpleTextWithoutSpace>
            <InvoiceInterest
              interestPercent={dataBilling.invoiceInterestPercent}
              dueDate={dataBilling.invoiceDueDate}
            />
          </S.SimpleTextWithoutSpace>
          <S.Status>
            <span>
              <InvoiceStatus status={dataBilling.invoiceStatus} />
            </span>
          </S.Status>
        </S.RecipientDataContainer>
      </S.RecipientDataWrapper>
      <S.Container>
        <S.Spacer className='spacer' />
        <S.TitleWithMarginBotton>PAGADOR</S.TitleWithMarginBotton>
        <S.SimpleTextWithoutSpace>
          {dataBilling.payerName}
        </S.SimpleTextWithoutSpace>
        <S.Text>
          <DocumentLabel number={dataBilling.payerDocumentNumber} />:
          <S.Space />
          <S.SimpleTextWithoutSpace>
            <DocumentNumber number={dataBilling.payerDocumentNumber} />
          </S.SimpleTextWithoutSpace>
        </S.Text>
        <S.SimpleTextWithoutSpace>
          {dataBilling.payerAddress}
        </S.SimpleTextWithoutSpace>
      </S.Container>
    </>
  );
};
