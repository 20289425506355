import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import { BillingPage } from '../pages';
import { Page404 } from '../pages/404';

export const Routes = () => {
  return (
    <Switch>
      <Route exact path='/boleto/:id'>
        <SnackbarProvider>
          <BillingPage />
        </SnackbarProvider>
      </Route>
      <Route path='*'>
        <Page404 />
      </Route>
    </Switch>
  );
};
