import React from 'react';
import { BaseButton } from '../../components/Buttons';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import { useSnackbar } from 'notistack';

export const CopyButton = ({ dataBilling, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();

  const doCopy = () => {
    const inputBilling = document.createElement('input');
    inputBilling.value = dataBilling.invoiceBarCode;
    document.body.appendChild(inputBilling);
    inputBilling.select();
    document.execCommand('copy');
    document.body.removeChild(inputBilling);
    enqueueSnackbar('Código copiado com sucesso!', { variant: 'success' });
  };

  return (
    <BaseButton startIcon={<Copy />} onClick={doCopy} {...props}>
      Copiar Código
    </BaseButton>
  );
};
