import axios from 'axios';

const baseURL = process.env.REACT_APP_API_HOST;

export const condofy = axios.create({ baseURL });

const condofyProtected = axios.create({
  baseURL
});

condofyProtected.interceptors.request.use(
  async (config) => {
    config.headers = {
      'Access-Token': process.env.REACT_APP_TOKEN
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export { condofyProtected };
