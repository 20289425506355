import React, { useRef } from 'react';

import { Payer } from './payer';
import { Bill } from './bill';
import { Recipient } from './recipient';
import { InvoiceActions } from './InvoiceActions';
import { RecipientData } from './recipientData';
import { BarCode } from './barCode';

import * as S from './styles';
import { ReactComponent as Logo } from '../../assets/logo.svg';

export const Billing = ({ dataBilling }) => {
  const componentRef = useRef(null);

  return (
    <S.Main>
      <S.ContainerHeader>
        <S.ContainerLogo>
          <Logo />
        </S.ContainerLogo>
        <InvoiceActions dataBilling={dataBilling} />
      </S.ContainerHeader>
      <S.Wrapper className='billing' ref={componentRef}>
        <S.Container className='billing-title'>
          <S.Title>Fatura</S.Title>
          <S.Spacer className='spacer' />
        </S.Container>
        <S.Container>
          <Payer dataBilling={dataBilling} />
          <S.Spacer className='spacer spacer-mobile' />
        </S.Container>
        <Bill dataBilling={dataBilling} />
        <InvoiceActions
          className='disable-mobile copy-and-print'
          dataBilling={dataBilling}
        />
        {dataBilling.invoiceItems.length > 5 && (
          <>
            <p
              className='page-break'
              style={{ pageBreakBefore: 'always', height: '195mm' }}
            />
          </>
        )}
        <S.RecipientWrapper>
          <Recipient dataBilling={dataBilling} />
          <S.Container>
            <RecipientData dataBilling={dataBilling} />
            {dataBilling.invoiceStatus === 'Pending' && (
              <S.Spacer className='spacer' />
            )}
          </S.Container>
          {dataBilling.invoiceStatus === 'Pending' && (
            <S.ContainerBarCode>
              <BarCode billingNumber={dataBilling.invoiceBarCode} />
              <InvoiceActions
                className='copy-and-print'
                dataBilling={dataBilling}
              />
            </S.ContainerBarCode>
          )}
        </S.RecipientWrapper>
      </S.Wrapper>
    </S.Main>
  );
};
