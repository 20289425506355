import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1143px;
  width: 100%;
  min-height: 800px;
  margin: 120px auto 0;
  box-sizing: border-box;
  padding: 20%;
  position: relative;
  z-index: 2;

  background: #fff;
  box-shadow: 0px 8px 16px rgba(23, 43, 77, 0.08);
  border-radius: 8px;

  .MuiCircularProgress-root {
    width: 180px;
    height: 180px;
  }
`;

export { Wrapper };
