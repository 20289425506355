import React from 'react';
import moment from 'moment';
import { DateText } from './DateText';

export const InvoiceInterest = ({ interestPercent, dueDate }) => {
  const dontPayDate = moment(dueDate).add(1, 'day');

  return (
    <div>
      Juros de {interestPercent}% ao mês a partir da data{' '}
      <DateText date={dontPayDate} />
    </div>
  );
};
