import React from 'react';
import {
  Table,
  TableHeader,
  TableBody,
  DataTableCell,
  TableCell
} from '@david.kucsai/react-pdf-table';
import { CurrencyText } from '../../components';
import { Text } from '@react-pdf/renderer';
import { lightGray } from './pdfStyles';

const styles = {
  tableHead: {
    border: 0,
    backgroundColor: lightGray,
    fontSize: 9,
    fontWeight: 600,
    padding: '2mm 5mm'
  },
  tableCell: {
    border: 0,
    borderBottomColor: lightGray,
    borderBottomWidth: 1,
    fontSize: 9,
    padding: '2mm 5mm'
  }
};

export const InvoiceItems = ({ items }) => {
  const tableCellOptions = {
    includeLeftBorder: false,
    includeTopBorder: false,
    includeRightBorder: false,
    includeBottomBorder: false
  };

  return (
    <Table data={items}>
      <TableHeader {...tableCellOptions}>
        <TableCell style={styles.tableHead}>Item</TableCell>
        <TableCell weighting={0.25} style={styles.tableHead}>
          Valor
        </TableCell>
      </TableHeader>
      <TableBody {...tableCellOptions}>
        <DataTableCell
          style={styles.tableCell}
          getContent={(r) => r.description}
        />
        <DataTableCell
          style={styles.tableCell}
          weighting={0.25}
          getContent={(r) => (
            <Text>
              <CurrencyText value={r.amount} />
            </Text>
          )}
        />
      </TableBody>
    </Table>
  );
};
