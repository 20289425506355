import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles } from './pdfStyles';
import {
  DateText,
  DocumentLabel,
  DocumentNumber,
  InvoiceFines,
  InvoiceInterest,
  InvoiceStatus,
  PaymentInstructions,
  PaymentLocation
} from '../../components';
import { utcToGmt } from '../../components/utils';

const PersonDocument = ({ title, name, documentNumber }) => (
  <View>
    <Text style={{ ...styles.label, marginBottom: 10 }}>{title}:</Text>
    <Text style={styles.text}>{name}</Text>
    <View style={{ flexDirection: 'row', marginBottom: 10 }}>
      <Text style={{ ...styles.label, marginRight: 5 }}>
        <DocumentLabel number={documentNumber} />
      </Text>
      <Text style={styles.text}>
        <DocumentNumber number={documentNumber} />
      </Text>
    </View>
  </View>
);

const Guarantor = ({ dataBilling }) =>
  dataBilling.guarantorDocumentNumber ? (
    <PersonDocument
      title='Beneficiário'
      name={dataBilling?.guarantorName}
      documentNumber={dataBilling.guarantorDocumentNumber}
    />
  ) : (
    <></>
  );

const Intermediary = ({ dataBilling }) => (
  <PersonDocument
    title='Intermediado por'
    name={dataBilling?.recipientName}
    documentNumber={dataBilling.recipientDocumentNumber}
  />
);

const LeftCol = ({ dataBilling }) => (
  <View style={{ ...styles.recipientColumn, ...styles.recipientSeparator }}>
    <View>
      <Guarantor dataBilling={dataBilling} />
      <Intermediary dataBilling={dataBilling} />
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        <View style={{ flexGrow: 1, marginBottom: 5 }}>
          <Text style={styles.label}>Nosso Número</Text>
          <Text style={styles.text}>{dataBilling.ourNumber}</Text>
        </View>
        <View style={{ flexGrow: 1, marginBottom: 5 }}>
          <Text style={styles.label}>Número do Documento</Text>
          <Text style={{ ...styles.text, flexWrap: 'wrap' }}>
            {dataBilling?.document}
          </Text>
        </View>
      </View>
    </View>
  </View>
);

const RightCol = ({ dataBilling }) => (
  <View style={styles.recipientColumn}>
    <View style={styles.recipientRightCol}>
      <View>
        <Text style={styles.label}>Data de Emissão</Text>
        <Text style={styles.text}>
          <DateText date={utcToGmt(dataBilling.invoiceCreatedDate)} />
        </Text>
      </View>
      <View>
        <Text style={styles.status}>
          <InvoiceStatus status={dataBilling.invoiceStatus} />
        </Text>
      </View>
    </View>
    <View style={{ marginBottom: 10 }}>
      <Text style={styles.label}>Local de Pagamento</Text>
      <Text style={styles.text}>
        <PaymentLocation />
      </Text>
    </View>
    <View style={{ marginBottom: 10 }}>
      <Text style={styles.label}>Instruções</Text>
      <Text style={styles.text}>
        <PaymentInstructions dataBilling={dataBilling} />
      </Text>
      <Text style={styles.text}>
        <InvoiceFines dataBilling={dataBilling} />
      </Text>
      <Text style={styles.text}>
        <InvoiceInterest
          interestPercent={dataBilling.invoiceInterestPercent}
          dueDate={dataBilling.invoiceDueDate}
        />
      </Text>
    </View>
    <View></View>
  </View>
);

export const Recipient = ({ dataBilling }) => (
  <View style={styles.recipient}>
    <LeftCol dataBilling={dataBilling} />
    <RightCol dataBilling={dataBilling} />
  </View>
);
