import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { getBillingInfo } from '../api/billing';

import { BillingPage as Page } from '../containers/Billing/BillingPage';

export const BillingPage = () => {
  const [isError, setIsError] = useState(false);
  const [dataBilling, setDataBilling] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    getBillingInfo(id)
      .then(({ data }) => {
        setDataBilling(data.data);
      })
      .catch(() => {
        setIsError(true);
        console.error('Erro');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return isError ? (
    <Redirect to='/' />
  ) : (
    <Page loading={loading} dataBilling={dataBilling} />
  );
};
