import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles, separatorColor } from './pdfStyles';
import { DocumentLabel, DocumentNumber } from '../../components';

const payerWrapperStyle = {
  borderTop: 1,
  borderBottom: 1,
  borderColor: separatorColor,
  marginBottom: 20,
  marginTop: 20,
  paddingTop: 10,
  paddingBottom: 10
};

export const Payer = ({ dataBilling }) => (
  <View style={payerWrapperStyle}>
    <Text style={{ ...styles.label, marginBottom: 10 }}>Pagador</Text>
    <Text style={styles.text}>{dataBilling.payerName}</Text>
    <View style={{ flexDirection: 'row' }}>
      <Text style={styles.label}>
        <DocumentLabel number={dataBilling.payerDocumentNumber} />:
      </Text>
      <Text style={styles.text}>
        <DocumentNumber number={dataBilling.payerDocumentNumber} />
      </Text>
    </View>
    <Text style={styles.text}>{dataBilling.payerAddress}</Text>
  </View>
);
